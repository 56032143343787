<template>
  <div>
    <b-card>
      <progress-bar-status
        :progress="1"
        :status="dataPendonor.status"
        responsive="sm"
      />
    </b-card>
    <div class="bg-white p-3 rounded mt-3">
      <b-table
        id="questionnaire-table"
        striped
        hover
        :items="items"
        :fields="fields"
        :per-page="perPage"
        :current-page="currentPage"
        small
      >
        <template v-slot:cell(answer)="data">
          <b-form-checkbox-group
            class="checkbox-group-answer d-flex"
            style="gap: 1.5rem; width: max-content"
            v-model="data.item.answer"
            @change="updateAnswer(data.item.id, data.item.answer)"
            stacked
          >
            <b-form-checkbox
              class="custom-checkbox"
              :value="option.value"
              :disabled="isCheckboxDisabled(data.item.id, option.value)"
              v-for="option in checkboxOptions"
              :key="option.value"
            >
              {{ option.label }}
            </b-form-checkbox>
          </b-form-checkbox-group>
        </template>
      </b-table>
      <div class="d-flex justify-content-between pl-3">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          aria-controls="questionnaire-table"
          small
        ></b-pagination>
        <b-button variant="primary" @click="showModal = true">Simpan</b-button>
      </div>
    </div>
    <b-modal
      id="modal"
      class="bg-black-0 rounded pr-16 pr-sm-32 mx-0 d-flex column mt-3"
      v-model="showModal"
      centered
      hide-header
      hide-footer
    >
      <h5 class="mb-0">Konfirmasi Edit Kuesioner Pendonor</h5>
      <p>
        Apakah anda akan melakukan edit kuesioner pada Pendonor Atas Nama
        <span class="font-weight-bold">{{ dataPendonor.nama }}</span>
        ?
      </p>
      <div class="d-flex justify-content-end flex-wrap mt-32">
        <b-button variant="text" @click="showModal = false"> Tutup </b-button>
        <b-button
          class="ml-16"
          variant="primary"
          :disabled="isLoading"
          @click="handleAction()"
        >
          Ya, Edit
          <b-spinner small label="Loading" v-if="isLoading"></b-spinner>
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import progressBarStatus from "@/view/main/dashboards/registrationdonation/components/common/ProgressBarStatus.vue";
import {
  BCard,
  BButton,
  BTable,
  BModal,
  BPagination,
  BFormCheckbox,
  BFormCheckboxGroup,
  BSpinner,
} from "bootstrap-vue";
import AktivasiPendonorService from "../../../../api/managependonor/managePendonorAPI";
import manageQuestionnaireAPI from "../../../../api/managequestionnaire/manageQuestionnaireAPI";
import manageDoctorAPI from "../../../../api/managedoctor/manageDoctorAPI";

export default {
  components: {
    progressBarStatus,
    BCard,
    BButton,
    BModal,
    BTable,
    BPagination,
    BFormCheckbox,
    BFormCheckboxGroup,
    BSpinner,
  },
  data() {
    return {
      isLoading: false,
      currentPage: 1,
      totalRows: 0,
      perPage: 21,
      currentDataPendonor: null,
      dataPendonor: null,
      showModal: false,
      fields: [
        { key: "id", label: "ID", sortable: true },
        { key: "description", label: "Description", sortable: true },
        { key: "answer", label: "Answer", sortable: false },
      ],
      items: [],
      answer: [],
      checkboxOptions: [
        { value: "YA", label: "Ya" },
        { value: "TIDAK", label: "Tidak" },
      ],
      disabledOptions: {},
    };
  },

  async mounted() {
    try {
      await this.fetchCurrentDataPendonor(this.$route.params.id);
      await Promise.all([
        this.fetchQuestionnaireList(this.currentPage),
        this.fetchQuestionaireRegistration(),
      ]);
    } catch (error) {
      console.error("Error during mounted lifecycle:", error);
    }
  },
  methods: {
    async fetchCurrentDataPendonor(id) {
      try {
        const response = await manageDoctorAPI.getDetail(id);
        this.dataPendonor = response.data.data;
        await this.fetchQuestionaireRegistration();
      } catch (error) {
        console.error("Error fetching current data pendonor:", error);
      }
    },
    async fetchQuestionnaireList(pageNumber) {
      try {
        const response = await manageQuestionnaireAPI.getAll({
          per_page: 50,
          page: pageNumber,
        });
        const questionnaireData = response.data.data.data;
        this.items = questionnaireData.map(({ id, description }) => ({
          id,
          description,
          answer: [],
        }));

        this.totalRows = response.data.data.total;
        this.updateItemsWithAnswers();
      } catch (error) {
        console.error("fetchQuestionnaireList failed:", error);
      }
    },
    async fetchQuestionaireRegistration() {
      if (!this.dataPendonor || !this.dataPendonor.registration_id) {
        console.error("dataPendonor or registration_id is not available");
        return;
      }
      try {
        const response =
          await AktivasiPendonorService.listRegistrationQuestionnaire(
            this.dataPendonor.registration_id
          );
        this.answer = JSON.parse(response.data.data.data_answer);
        this.updateItemsWithAnswers();
      } catch (error) {
        console.error("Error fetching questionnaire registration:", error);
      }
    },
    updateItemsWithAnswers() {
      const disabledOptions = {};

      this.items = this.items.map((item) => {
        const answerObj = this.answer.find(
          (ans) => Number(ans.question_id) === item.id
        );

        // Jika ada jawaban, disable kedua checkbox
        if (answerObj && answerObj.answer) {
          disabledOptions[item.id] = { YA: true, TIDAK: true };
        } else {
          // Jika tidak ada jawaban, aktifkan kedua checkbox
          disabledOptions[item.id] = { YA: false, TIDAK: false };
        }

        return {
          ...item,
          answer: answerObj ? [answerObj.answer] : [],
        };
      });

      this.disabledOptions = disabledOptions;
    },

    isCheckboxDisabled(questionId, value) {
      return this.disabledOptions[questionId]?.[value] ?? false;
    },

    updateAnswer(questionId, answer) {
      const itemIndex = this.items.findIndex((item) => item.id === questionId);
      if (itemIndex !== -1) {
        if (answer.length > 1) {
          // If more than one checkbox is selected, keep only the last selected value
          answer = [answer[answer.length - 1]];
        }
        this.$set(this.items[itemIndex], "answer", answer);

        // Update answer in the main answer array
        const answerIndex = this.answer.findIndex(
          (ans) => Number(ans.question_id) === questionId
        );
        if (answerIndex !== -1) {
          this.$set(this.answer, answerIndex, {
            question_id: questionId,
            answer: answer[0],
          });
        } else {
          this.answer.push({
            question_id: questionId,
            answer: answer[0],
          });
        }

        // Disable/enable checkbox yang lain
        if (answer.length === 1) {
          // Jika ada jawaban, disable checkbox yang lain
          this.disabledOptions[questionId] = {
            YA: answer[0] === "TIDAK",
            TIDAK: answer[0] === "YA",
          };
        } else {
          // Jika tidak ada jawaban, aktifkan kedua checkbox
          this.disabledOptions[questionId] = { YA: false, TIDAK: false };
        }
      }
    },
    async handleAction() {
      try {
        this.isLoading = true;
        const response =
          await AktivasiPendonorService.updateRegistrationQuestion({
            registration_id: this.dataPendonor.registration_id,
            data_answer: this.answer,
          });
        console.log(response);
        await this.fetchQuestionaireRegistration();
      } catch (error) {
        console.error("Error updating questionnaire:", error);
        this.isLoading = false;
      } finally {
        this.showModal = false;
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
@media (max-width: 720px) {
  .checkbox-group-answer {
    flex-direction: column;
    gap: 0 !important;
  }
}
</style>
